import { MAIN_COLOR } from '@/constant'
import { getItem } from '@/utils/storage'
import { generateColors } from '@/utils/theme'
const getters = {
  token: (state: any) => state.user.token,
  userInfo: (state: any) => state.user.userInfo,
  hasUserInfo: (state: any) => {
    return JSON.stringify(state.user.userInfo) !== '{}'
  },
  cssVar: (state: any) => {
    return {
      ...state.theme.variables,
      ...generateColors(getItem(MAIN_COLOR))
    }
  },
  sidebarOpened: (state: any) => state.app.sidebarOpened,
  mainColor: (state: any) => state.theme.mainColor,
  tColor: (state: any) => state.theme.tColor,
  menuColor: (state: any) => state.theme.menuColor,
  tagsViewList: (state: any) => state.app.tagsViewList,
  orderList: (state: any) => state.order.orderList,
  canInvoiceList: (state: any) => state.invoice.canInvoiceList,
  invoicedList: (state: any) => state.invoiced.invoicedList
}
export default getters
