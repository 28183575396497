import request from '@/service'
import { IOrderListParams, IOrderResult, IDeviceListParams, IDeviceResult, IOrderExportsParams, IOrderDetailParams, IOrderDetailResult, IOrderCancelParams, IOrderCreateParams, ICouponItem, IWaybillInfoResult, IOrderApplyParams, IApplyInvoicedParams, IOrderConfirmParams } from './types'
import { orderApi } from './constant'
import { BasicResult } from '../sys/types'
import axios from 'axios'

// 订单列表
export const getOrderList = (params: IOrderListParams) => {
  return request.get<BasicResult<IOrderResult>>({
    url: orderApi.orderList,
    params
  })
}

// 设备列表
export const getDeviceList = (params: IDeviceListParams) => {
  return request.get<BasicResult<IDeviceResult>>({
    url: orderApi.deviceList,
    params,
    showLoading: false
  })
}

// 地址查询
export const getAddressByCode = (params: { keyword: string }) => {
  return axios.get(orderApi.addressByCode + params.keyword).then((res) => {
    return res.data
  })
}

// 订单导出
export const orderExports = (params: IOrderExportsParams) => {
  return request.get<Blob>({
    url: orderApi.orderExports,
    params,
    responseType: 'blob'
  })
}

// 订单详情
export const orderDetailInfo = (params: IOrderDetailParams) => {
  return request.get<BasicResult<IOrderDetailResult>>({
    url: orderApi.orderDetailInfo,
    params
  })
}

// 运单详情
export const waybillInfo = (params: { orderId: bigint }) => {
  return request.get<BasicResult<IWaybillInfoResult>>({
    url: orderApi.waybillInfo,
    params
  })
}

// 订单取消
export const orderCancel = (data: IOrderCancelParams) => {
  return request.post<BasicResult<undefined>>({
    url: orderApi.orderCancel,
    data
  })
}

// 获取地址code
export const getRegeoCode = (params: { location: string }) => {
  return axios.get(orderApi.regeoCode + params.location).then((res) => {
    return res.data
  })
}

// 创建订单
export const orderCreate = (data: IOrderCreateParams) => {
  return request.post<BasicResult<null>>({
    url: orderApi.orderCreate,
    data
  })
}

// 优惠券列表
export const couponList = (params: { orderId: bigint }) => {
  return request.get<BasicResult<ICouponItem[]>>({
    url: orderApi.couponList,
    params
  })
}

// 新增回款
export const orderApply = (params: IOrderApplyParams) => {
  return request.put<BasicResult<any>>({
    url: orderApi.orderApply,
    data: params
  })
}

// 申请开票
export const applyInvoiced = (params: IApplyInvoicedParams) => {
  return request.post<BasicResult<any>>({
    url: orderApi.applyInvoiced,
    data: params
  })
}

// 申请退款
export const applyRefund = (params: { orderId: bigint }) => {
  return request.get<BasicResult<any>>({
    url: orderApi.applyRefund,
    params
  })
}
// 订单确认
export const orderConfirm = (data: IOrderConfirmParams) => {
  return request.post<BasicResult<any>>({
    url: orderApi.orderConfirm,
    data
  })
}
