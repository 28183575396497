import { IOrderResult, IOrderItem, IOrderListParams, IDeviceListParams, IDeviceResult, IOrderExportsParams, INavigator, IOrderDetailParams, IOrderDetailResult, IOrderCancelParams, IAssistantInputtips, ITipsItem, IRegeoCodeResult, IRegeocode, IOrderCreateParams, IWaybillInfoResult, ICouponItem, IWaybillItem } from '@/service/api/order/types'
import { BasicResult } from '@/service/request/types'
import { IAddressTips, IOrderState } from './types'
import { getAddressByCode, getDeviceList, getOrderList, getRegeoCode, orderCancel, orderDetailInfo, orderExports, orderCreate, waybillInfo, couponList } from '@/service/api/order'
import { PAGE_NUM, PAGE_SIZE } from '@/constant'
import store from '..'

export default {
  namespaced: true,
  state: () => ({
    orderList: [],
    total: 100,
    queryForm: {
      orderStatusCategory: 1,
      pageNum: PAGE_NUM,
      pageSize: PAGE_SIZE
    },
    loading: false,
    createOrderIsShow: false,
    callUsIsShow: false,
    orderDetailInfo: {},
    deviceList: [],
    receiveAddress: '',
    currentOrderId: 0,
    addressTipsList: [],
    currentAddressType: '',
    currentAddressRegeo: {},
    currentOrderNo: '',
    waybillInfo: {},
    couponList: [],
    currentWaybill: {},
    currentPosition: false,
    sendPosition: false,
    receivePosition: false
  }),
  mutations: {
    setOrderList(state: IOrderState, orderList: IOrderItem[]) {
      state.orderList = orderList
    },
    setOrderStatusCategory(state: IOrderState, orderStatusCategory: string) {
      state.queryForm.orderStatusCategory = orderStatusCategory
    },
    setPageNum(state: IOrderState, pageNum: string) {
      state.queryForm.pageNum = pageNum
    },
    setPageSize(state: IOrderState, pageSize: string) {
      state.queryForm.pageSize = pageSize
    },
    setTotal(state: IOrderState, total: number) {
      state.total = total
    },
    setQueryForm(state: IOrderState, queryForm: IOrderListParams) {
      state.queryForm = queryForm
    },
    setLoading(state: IOrderState, loading: boolean) {
      state.loading = loading
    },
    setCreateOrderIsShow(state: IOrderState, createOrderIsShow: boolean) {
      state.createOrderIsShow = createOrderIsShow
    },
    setCallUsIsShow(state: IOrderState, callUsIsShow: boolean) {
      state.callUsIsShow = callUsIsShow
    },
    setOrderDetailInfo(state: IOrderState, orderDetailInfo: IOrderDetailResult) {
      state.orderDetailInfo = orderDetailInfo
    },
    setDeviceList(state: IOrderState, deviceListObj: any) {
      const { deviceList, equipmentBrand, equipmentModel } = deviceListObj
      const arrData: any = JSON.parse(JSON.stringify(deviceList))
      const brandArr: any = []
      const brandDataArr: any = []
      for (let i = 0; i < arrData.length; i++) {
        if (brandArr.indexOf(arrData[i].brandName) === -1) {
          brandDataArr.push({
            label: arrData[i].brandName_dictText,
            value: arrData[i].brandName_dictText,
            children: [arrData[i]]
          })
          brandArr.push(arrData[i].brandName)
        } else {
          for (let j = 0; j < brandDataArr.length; j++) {
            if (brandDataArr[j].value === arrData[i].brandName_dictText) {
              brandDataArr[j].children.push(arrData[i])
              break
            }
          }
        }
      }
      for (let i = 0; i < brandDataArr.length; i++) {
        for (let j = 0; j < equipmentBrand.length; j++) {
          if (equipmentBrand[j].value === brandDataArr[i].value) {
            brandDataArr[i].label = equipmentBrand[j].text
          }
        }
        const modeArr = []
        const modeDataArr = []
        for (let j = 0; j < brandDataArr[i].children.length; j++) {
          if (modeArr.indexOf(brandDataArr[i].children[j].carType) === -1) {
            modeDataArr.push({
              label: brandDataArr[i].children[j].carType_dictText,
              value: brandDataArr[i].children[j].carType_dictText,
              children: [brandDataArr[i].children[j]]
            })
            modeArr.push(brandDataArr[i].children[j].carType)
          } else {
            for (let q = 0; q < modeDataArr.length; q++) {
              if (modeDataArr[q].value === brandDataArr[i].children[j].carType_dictText) {
                modeDataArr[q].children.push(brandDataArr[i].children[j])
                break
              }
            }
          }
        }
        brandDataArr[i].children = modeDataArr
      }
      for (let i = 0; i < brandDataArr.length; i++) {
        for (let q = 0; q < brandDataArr[i].children.length; q++) {
          for (let j = 0; j < brandDataArr[i].children[q].children.length; j++) {
            brandDataArr[i].children[q].children[j].value = brandDataArr[i].children[q].children[j].carModel
            brandDataArr[i].children[q].children[j].label = brandDataArr[i].children[q].children[j].carModel
            brandDataArr[i].children[q].children[j].children = [
              { label: '新', value: `${brandDataArr[i].children[q].children[j].deviceNo}-${brandDataArr[i].children[q].children[j].goodsWeight}-${brandDataArr[i].children[q].children[j].id}-新` },
              { label: '旧', value: `${brandDataArr[i].children[q].children[j].deviceNo}-${brandDataArr[i].children[q].children[j].goodsWeight}-${brandDataArr[i].children[q].children[j].id}-旧` }
            ]
          }
          for (let j = 0; j < equipmentModel.length; j++) {
            if (equipmentModel[j].value === brandDataArr[i].children[q].value) {
              brandDataArr[i].children[q].label = equipmentModel[j].text
            }
          }
        }
      }
      state.deviceList = brandDataArr
    },
    setReceiveAddress(state: IOrderState, receiveAddress: any) {
      state.receiveAddress = receiveAddress
    },
    setCurrentOrderId(state: IOrderState, orderId: bigint) {
      state.currentOrderId = orderId
    },
    setAddressTipsList(state: IOrderState, addressTipsList: IAddressTips[]) {
      state.addressTipsList = addressTipsList
    },
    setCurrentAddressType(state: IOrderState, currentAddressType: string) {
      state.currentAddressType = currentAddressType
    },
    setCurrentAddressRegeo(state: IOrderState, currentAddressRegeo: IRegeocode) {
      state.currentAddressRegeo = currentAddressRegeo
    },
    setCurrentOrderNo(state: IOrderState, currentOrderNo: string) {
      state.currentOrderNo = currentOrderNo
    },
    setWaybillInfo(state: IOrderState, waybillInfo: IWaybillInfoResult) {
      state.waybillInfo = waybillInfo
    },
    setCouponList(state: IOrderState, couponList: ICouponItem[]) {
      state.couponList = couponList
    },
    setCurrentWaybill(state: IOrderState, currentWaybill: IWaybillItem) {
      state.currentWaybill = currentWaybill
    },
    setCurrentPosition(state: IOrderState, currentPosition: [number, number] | false) {
      state.currentPosition = currentPosition
    },
    setSendPosition(state: IOrderState, sendPosition: [number, number] | false) {
      state.sendPosition = sendPosition
    },
    setReceivePosition(state: IOrderState, receivePosition: [number, number] | false) {
      state.receivePosition = receivePosition
    }
  },
  actions: {
    async getOrderList(context: any, orderListParams: IOrderListParams) {
      context.commit('setLoading', true)
      await getOrderList(orderListParams).then((res: BasicResult<IOrderResult>) => {
        if (res.success) {
          context.commit('setOrderList', res.data.records)
          context.commit('setOrderStatusCategory', orderListParams.orderStatusCategory)
          context.commit('setPageNum', orderListParams.pageNum)
          context.commit('setPageSize', orderListParams.pageSize)
          context.commit('setTotal', res.data.total)
        }
        context.commit('setLoading', false)
      })
    },
    async getDeviceList(context: any) {
      const orderListParams: IDeviceListParams = {
        pageNum: '1',
        pageSize: '2000',
        filter: {}
      }
      await getDeviceList(orderListParams).then(async (res: BasicResult<IDeviceResult>) => {
        context.commit('setDeviceList', {
          deviceList: JSON.parse(JSON.stringify(res.data.list)),
          equipmentBrand: JSON.parse(JSON.stringify(store.state.user.allDictItems.equipmentBrand)),
          equipmentModel: JSON.parse(JSON.stringify(store.state.user.allDictItems.equipmentModel))
        })
      })
    },
    getAddressByCode(context: any, params: { keyword: string, currentAddressType: string }) {
      context.commit('setCurrentAddressType', params.currentAddressType)
      getAddressByCode(params).then((res: IAssistantInputtips) => {
        if (res.status === '1') {
          const addressTipsList: IAddressTips[] = res.tips.map((item: ITipsItem) => {
            return {
              text: item.district + item.address + item.name,
              value: item.location
            }
          })
          context.commit('setAddressTipsList', addressTipsList)
        }
      })
    },
    orderExport(context: any, params: IOrderExportsParams) {
      orderExports(params).then((res: Blob) => {
        const navigator: INavigator = window.navigator
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(new Blob([res], { type: 'application/vnd.ms-excel' }), '订单列表' + '.xls')
        } else {
          const url = window.URL.createObjectURL(new Blob([res], { type: 'application/vnd.ms-excel' }))
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', '订单列表' + '.xls')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
        }
      })
    },
    async getOrderDetailInfo(context: any, params: IOrderDetailParams) {
      await orderDetailInfo(params).then((res: BasicResult<IOrderDetailResult>) => {
        context.commit('setOrderDetailInfo', res.data)
        context.commit('setCurrentOrderId', params.orderId)
      })
    },
    async orderCancel(context: any, params: IOrderCancelParams) {
      await orderCancel(params).then((res: BasicResult<any>) => {
        return res
      })
    },
    async getRegeoCode(context: any, params: { location: string }) {
      await getRegeoCode(params).then((res: IRegeoCodeResult) => {
        context.commit('setCurrentAddressRegeo', res.regeocode)
      })
    },
    async orderCreate(context: any, params: IOrderCreateParams) {
      await orderCreate(params).then((res: BasicResult<null>) => {
        if (res.success) {
          context.commit('setCreateOrderIsShow', false)
        }
        return res.success
      })
    },
    async getWaybillInfo(context: any, params: { orderId: bigint }) {
      await waybillInfo(params).then((res: BasicResult<IWaybillInfoResult>) => {
        if (res.success) {
          context.commit('setWaybillInfo', res.data)
        }
      })
    },
    async getCouponList(context: any, params: { orderId: bigint }) {
      couponList(params).then((res: BasicResult<ICouponItem[]>) => {
        if (res.success) {
          res.data = res.data.filter((v) => v.available)
          res.data.forEach(element => {
            if(element.available){
              element.isCheck = false
            }
          });
          context.commit('setCouponList', res.data)
        }
      })
    },
    async setCurrentWaybillAsync(context: any, currentWaybill: IWaybillItem) {
      context.commit('setCurrentWaybill', currentWaybill)
    }
  }
}
