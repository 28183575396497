import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const layout = () => import('@/layout/index.vue')

/**
 * 私有路由表
 */
const privateRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: layout,
    redirect: '/order/index',
    children: [
      {
        path: '/order/index',
        component: () => import(/* webpackChunkName: "order" */'@/views/order/index.vue'),
        meta: {
          title: '订单列表',
          icon: 'article'
        }
      }
    ]
  },
  {
    path: '/transport',
    component: layout,
    redirect: '/transport/index',
    meta: {
      title: '运输管理',
      icon: 'example'
    },
    children: [
      {
        path: '/transport/index',
        component: () => import(/* webpackChunkName: "transport" */'@/views/transport/index.vue'),
        meta: {
          title: '运输查询',
          icon: 'guide'
        }
      }
    ]
  },
  {
    path: '/invoice',
    component: layout,
    redirect: '/invoice/index',
    meta: {
      title: '发票管理',
      icon: 'article'
    },
    children: [
      {
        path: '/invoice/index',
        component: () => import(/* webpackChunkName: "invoice" */'@/views/invoice/index.vue'),
        meta: {
          title: '开票申请列表',
          icon: 'personnel-info'
        }
      },
      {
        path: '/invoice/invoiced',
        component: () => import(/* webpackChunkName: "invoice" */'@/views/invoice/invoiced.vue'),
        meta: {
          title: '已开票列表',
          icon: 'article-ranking'
        }
      }
    ]
  },
  {
    path: '/about',
    component: layout,
    redirect: '/about/index',
    children: [
      {
        path: '/about/index',
        component: () => import(/* webpackChunkName: "about" */'@/views/about/index.vue'),
        meta: {
          title: '了解港机汇',
          icon: 'link'
        }
      }
    ]
  },
  {
    path: '/mine',
    component: layout,
    redirect: '/mine/index',
    children: [
      {
        path: '/mine/index',
        component: () => import(/* webpackChunkName: "mine" */'@/views/mine/index.vue'),
        meta: {
          title: '账号管理',
          icon: 'user'
        }
      }
    ]
  },
  {
    path: '/sys',
    component: layout,
    redirect: '/sys/index',
    meta: {
      title: '系统设置',
      icon: 'personnel-manage'
    },
    children: [
      {
        path: '/sys/index',
        component: () => import(/* webpackChunkName: "sys" */'@/views/sys/index.vue'),
        meta: {
          title: '字典管理',
          icon: 'article-create'
        }
      },
    ]
  }
]

const publicRoutes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/error-page/404.vue')
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/error-page/401.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: [...publicRoutes, ...privateRoutes]
})

export default router
