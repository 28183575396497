import request from '@/service'
import { ICanInvoiceListParams, ICanInvoiceListResult, IInvoicedListParams, IInvoicedListResult, IInvoiceExParams, IIvoiceInfoParams, IIvoiceInfoResult, ISendIvoicedInfoParams, ISendIvoicedInfoResult } from './types'
import { invoiceApi } from './constant'
import { BasicResult } from '../sys/types'

// 获取开票申请列表
export const getCanInvoiceList = (params: ICanInvoiceListParams) => {
  return request.get<BasicResult<ICanInvoiceListResult>>({
    url: invoiceApi.canInvoiceList,
    params
  })
}
// 获取开票信息
export const getInvoiceInfo = (orderId: any) => {
  return request.get<BasicResult<IIvoiceInfoResult>>({
    url: invoiceApi.invoiceInfo + orderId
  })
}

// 获取已开票列表
export const getInvoicedList = (params: IInvoicedListParams) => {
  return request.get<BasicResult<IInvoicedListResult>>({
    url: invoiceApi.invoicedList,
    params
  })
}

// 获取寄票信息
export const getSendInvoicedInfo = (params: ISendIvoicedInfoParams) => {
  return request.get<BasicResult<ISendIvoicedInfoResult>>({
    url: invoiceApi.sendInvoicedInfo + '/' + params.orderId
  })
}

// 导出开票申请列表
export const invoiceExport = (params: IInvoiceExParams) => {
  return request.get<Blob>({
    url: invoiceApi.invoiceExport,
    params,
    responseType: 'blob'
  })
}

// 导出开票列表
export const invoicedExport = (params: IInvoiceExParams) => {
  return request.get<Blob>({
    url: invoiceApi.invoicedExport,
    params,
    responseType: 'blob'
  })
}
