export enum orderApi {
  orderList = 'order/userBackstage/getOrderList',
  deviceList = 'admin/sys/device/list',
  addressByCode = 'https://restapi.amap.com/v3/assistant/inputtips?key=c5dc8231833f0a99386f9f77c449a9d7&keywords=',
  orderExports = 'order/userBackstage/transport/order/export/excel',
  orderDetailInfo = 'order/userBackstage/getOrderDetails',
  orderCancel = 'order/cancel/order',
  regeoCode = 'https://restapi.amap.com/v3/geocode/regeo?key=c5dc8231833f0a99386f9f77c449a9d7&location=',
  orderCreate = 'order/userBackstage/createTransportOrder',
  couponList = 'cos/cos/wx/selectlist',
  waybillInfo = 'order/waybill/get/transport/record',
  orderApply = 'fins/fin-trasfer/add',
  applyInvoiced = 'fins/fin/invoice/add',
  applyRefund = 'fins/fin/refund/apply/add',
  orderConfirm = 'order/confirmOrder'
}
