import { InvoiceState, PAGE_NUM, PAGE_SIZE } from '@/constant'
import {
  getInvoicedList,
  getSendInvoicedInfo,
  invoicedExport
} from '@/service/api/invoice'
import {
  IInvoicedListParams,
  IInvoicedItem,
  IInvoicedListResult,
  ISendIvoicedInfoResult,
  ISendIvoicedInfoParams,
  IInvoicedExParams
} from '@/service/api/invoice/types'
import { BasicResult } from '@/service/request/types'
import { blobExport } from '@/utils/export'
import { IInvoicedState } from './types'

export default {
  namespaced: true,
  state: () => ({
    invoicedList: [],
    total: 100,
    loading: false,
    queryForm: {
      state: InvoiceState.invoiced,
      pageNum: PAGE_NUM,
      pageSize: PAGE_SIZE
    },
    sendInvoicedInfo: {}
  }),
  mutations: {
    setInvoicedList(state: IInvoicedState, invoicedList: IInvoicedItem[]) {
      state.invoicedList = invoicedList
    },
    setPageNum(state: IInvoicedState, pageNum: number) {
      state.queryForm.pageNum = pageNum
    },
    setPageSize(state: IInvoicedState, pageSize: number) {
      state.queryForm.pageSize = pageSize
    },
    setTotal(state: IInvoicedState, total: number) {
      state.total = total
    },
    setLoading(state: IInvoicedState, loading: boolean) {
      state.loading = loading
    },
    setQueryForm(state: IInvoicedState, queryForm: IInvoicedListParams) {
      state.queryForm = queryForm
    },
    setSendInvoicedInfo(
      state: IInvoicedState,
      sendInvoicedInfo: ISendIvoicedInfoResult
    ) {
      state.sendInvoicedInfo = sendInvoicedInfo
    }
  },
  actions: {
    async getInvoicedList(
      context: any,
      invoicedListParams: IInvoicedListParams
    ) {
      context.commit('setLoading', true)
      await getInvoicedList(invoicedListParams).then(
        (res: BasicResult<IInvoicedListResult>) => {
          if (res.success) {
            context.commit('setInvoicedList', res.data.records)
            context.commit('setPageNum', invoicedListParams.pageNum)
            context.commit('setPageSize', invoicedListParams.pageSize)
            context.commit('setTotal', res.data.total)
          }
          context.commit('setLoading', false)
        }
      )
    },
    async setSendInvoicedInfo(
      context: any,
      sendIinvoicedListParams: ISendIvoicedInfoParams
    ) {
      getSendInvoicedInfo(sendIinvoicedListParams).then(
        (res: BasicResult<ISendIvoicedInfoResult>) => {
          context.commit('setSendInvoicedInfo', res.data)
        }
      )
    },
    async invoicedExport(context: any, params: IInvoicedExParams) {
      context.commit('setLoading', true)
      await invoicedExport(params).then((res: Blob) => {
        blobExport(res, '已开票列表')
        context.commit('setLoading', false)
      })
    }
  }
}
